import { Box, IconButton, Stack, Typography } from '@mui/material';
import { PropsWithChildren, ReactElement, ReactNode, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export interface FilterAreaWithCollapsibleChildrenProps {
    children: ReactElement<FilterSectionProps>[];
}

export default function FilterAreaWithCollapsibleChildren(props: FilterAreaWithCollapsibleChildrenProps) {
    const { children } = props;
    const [toggleStates, setToggleStates] = useState(children.map(c => !c.props.openByDefault));

    return (
        <Stack
            direction='column'
            spacing={1}
            mt={2}
            px={2}>
            {
                children.map((c, i) =>
                    <CollapsibleFilterSection
                        key={i}
                        title={c.props.title}
                        collapsed={toggleStates[i]}
                        onToggleClick={() => setToggleStates(toggleStates.map((val, ti) => ti === i ? !val : val))}
                    >
                        {c.props.children}
                    </CollapsibleFilterSection>)
            }
        </Stack>
    );
}

export type FilterSectionProps = PropsWithChildren<{
    title: string;
    openByDefault?: boolean;
}>;

export const FilterSection = (props: FilterSectionProps) => {
    return (
        <>
            {props.children}
        </>
    );
};

interface CollapsibleFilterSectionProps {
    title: string;
    collapsed: boolean;
    children: ReactNode;
    onToggleClick: () => void;
}

function CollapsibleFilterSection(props: CollapsibleFilterSectionProps) {
    const { title, children, collapsed, onToggleClick } = props;
    return (
        <Stack direction='column'>
            <Box>
                <Box display='flex' flexDirection='row'>
                    <Typography
                        mt={1}
                        variant='subtitle1'
                    >
                        {title}
                    </Typography>
                    <Box flexGrow={1} />
                    <IconButton onClick={onToggleClick}>
                        {
                            collapsed ? <AddIcon /> : <RemoveIcon />
                        }
                    </IconButton>
                </Box>
                {/* <Collapse in={!collapsed}>
                    
                </Collapse> */}
                {collapsed ? <></> : children}
            </Box>
        </Stack>
    );
}