import { Box } from '@mui/material';
import FilterAreaProps from './filterAreaProps';
import FILTER_KEYS from '../models/pcPartFilterKeys';
import RangeSlider from './RangeSlider';

export interface PriceFilterProps extends FilterAreaProps {
    minPrice: number;
    maxPrice: number;
}

export default function PriceFilter(props: PriceFilterProps) {
    const { minPrice, maxPrice, filters, onFilterUpdate } = props;
    
    return (
        <Box
            pt={5}
            ml={3}
        >
            <RangeSlider
                min={minPrice}
                max={maxPrice}
                step={500}
                unitLabel='LKR'
                value={{
                    min: +(filters.getFirstValue(FILTER_KEYS.priceMin) ?? minPrice),
                    max: +(filters.getFirstValue(FILTER_KEYS.priceMax) ?? maxPrice)
                }}
                onChange={(range) => {
                    onFilterUpdate(FILTER_KEYS.priceMin, [range.min.toString()]);
                    onFilterUpdate(FILTER_KEYS.priceMax, [range.max.toString()]);
                }} />
        </Box >
    );
}