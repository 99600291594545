import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PCPart } from '../features/shared/models/pcPart';
import { PCPartType } from '../features/shared/models/pcPartType';
import pcPartsAPI from '../features/pcpart-browsing/pcPartsAPI';

export interface AppState {
    isLoading: boolean;
    pcParts: PCPart[];
}
const initialState: AppState = {
    isLoading: false,
    pcParts: []
};

export const loadPCPartsAsync = createAsyncThunk(
    'app/loadPCParts',
    async (arg: { pcPartType: PCPartType, searchPhrase: string }) => {
        return await pcPartsAPI.searchPCParts(arg.pcPartType, arg.searchPhrase);
    }
);

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(loadPCPartsAsync.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(loadPCPartsAsync.fulfilled, (state, action) => {
            state.pcParts = action.payload;
            state.isLoading = false;
        });
        builder.addCase(loadPCPartsAsync.rejected, (state) => {
            state.pcParts = [];
            state.isLoading = false;
        });
    }
});
export default appSlice.reducer;