import FILTER_KEYS from '../../pcpart-filtering/models/pcPartFilterKeys';

const QUERY_PARAM_KEYS = {
    phrase: 'phrase',
    pcPartType: 'type',
    sortBy: 'sort',
    viewMode: 'view',
    ...FILTER_KEYS
};

export default QUERY_PARAM_KEYS;