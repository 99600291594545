const FILTER_KEYS = {
    manufacturer: 'mfgr',
    pcPartStore: 'store',
    priceMin: 'priceMin',
    priceMax: 'priceMax',
    series: 'series',
    cores: 'cores',
    chipset: 'chip',
    vRam: 'vRam',
    memoryType: 'memType',
    memorySpeed: 'speed',
    wattageMin: 'minWatts',
    wattageMax: 'maxWatts',
    psuRating: 'rating',
    capacity: 'capacity',
    ssdType: 'ssdType'
};
export default FILTER_KEYS;