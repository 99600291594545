import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import FilterAreaProps from './filterAreaProps';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';
import FILTER_KEYS from '../models/pcPartFilterKeys';

const CHIP_MANUFACTURERS = ['Nvidia', 'AMD', 'Intel'];
const VRAM = ['1', '2', '4', '6', '8', '10', '12', '14', '16', '20', '24'];

export default function GraphicsCardFilter(props: FilterAreaProps) {
    const { filters, onFilterUpdate } = props;
    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Chips'>
                <FilterCheckboxesGroup
                    hasAnyValueCheckbox={true}
                    referenceValues={CHIP_MANUFACTURERS}
                    selectedValues={filters.getValues(FILTER_KEYS.chipset)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.chipset, values)}
                />
            </FilterSection>
            <FilterSection title='Memory'>
                <FilterCheckboxesGroup
                    referenceValues={VRAM}
                    selectedValues={filters.getValues(FILTER_KEYS.vRam)}
                    labelFormatter={(vram) => `${vram} GB`}
                    onSelectionChange={values => onFilterUpdate(FILTER_KEYS.vRam, values)}
                />
            </FilterSection>
        </FilterAreaWithCollapsibleChildren>
    );
}