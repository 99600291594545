import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { PCPart } from '../../shared/models/pcPart';
import { getByPropertyPath } from '../../../common/helpers';
import { useMemo } from 'react';

function descendingComparator(a: PCPart, b: PCPart, orderBy: PCPartTableHeaderColumn) {
    const aVal = getByPropertyPath<string>(a, orderBy.propertyPath) ?? '';
    const bVal = getByPropertyPath<string>(b, orderBy.propertyPath) ?? '';
    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator(
    order: Order,
    orderBy: PCPartTableHeaderColumn,
): (
    a: PCPart,
    b: PCPart
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export interface PCPartTableHeaderColumn {
    label: string;
    propertyPath: string;
    formatter?: (value: unknown) => string;
}

export interface PCPartTableProps {
    tableHeaderColumns: PCPartTableHeaderColumn[],
    pcParts: PCPart[],
    onRowClicked: (pcPart: PCPart) => void;
}

export default function PCPartsTable(props: PCPartTableProps) {
    const { tableHeaderColumns, pcParts, onRowClicked } = props;

    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<PCPartTableHeaderColumn | null>(null);

    const handleSortRequest = (column: PCPartTableHeaderColumn) => {
        const isAsc = orderBy === column && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(column);
    };

    const sortedPCParts = useMemo(() => {
        return orderBy ? [...pcParts].sort(getComparator(order, orderBy)) : pcParts;
    }, [pcParts, orderBy, order]);

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        
                    >
                        <TableHead>
                            <TableRow>
                                {
                                    tableHeaderColumns.map((col) => (
                                        <TableCell
                                            key={col.label}
                                            sortDirection={orderBy === col ? order : false}
                                        >
                                            <TableSortLabel
                                                active={orderBy === col}
                                                direction={orderBy === col ? order : 'asc'}
                                                onClick={() => handleSortRequest(col)}
                                            >
                                                {col.label}
                                                {orderBy === col ? (
                                                    <Box component="span" sx={visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </Box>
                                                ) : null}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sortedPCParts.map(pcPart =>
                                    <TableRow
                                        hover
                                        onClick={() => onRowClicked(pcPart)}
                                        key={pcPart.id}
                                        tabIndex={-1}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {
                                            tableHeaderColumns.map(col => {
                                                const value = getByPropertyPath<string>(pcPart, col.propertyPath);
                                                return <TableCell key={`${pcPart.id}::${col.label}`}>
                                                    {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                                                    {value && col.formatter ? col.formatter(value) : value ?? '-'}
                                                </TableCell>;
                                            }
                                            )
                                        }
                                    </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}