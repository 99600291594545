import { PCPartType } from '../../shared/models/pcPartType';
import { PCPartTableHeaderColumn as PCPartTableColumn } from '../components/PCPartsTable';

const nameColumn = {
    propertyPath: 'name',
    label: 'Name',
};

const priceColumn: PCPartTableColumn = {
    label: 'Average Price',
    propertyPath: 'averagePrice',
    formatter: (value) => `${(value as number).toLocaleString()} LKR`
};

const processorTableConfig: PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Cores',
        propertyPath: 'specifications.coreCount',
    },
    {
        label: 'Base Clock',
        propertyPath: 'specifications.baseClock',
        formatter: (value) => `${value} GHZ`
    },
    {
        label: 'Boost Clock',
        propertyPath: 'specifications.boostClock',
        formatter: (value) => `${value} GHZ`
    },
    {
        label: 'L3 Cache',
        propertyPath: 'specifications.l3Cache',
        formatter: (value) => `${value} MB`
    },
    {
        label: 'TDP',
        propertyPath: 'specifications.tdp',
        formatter: (value) => `${value} W`
    },
    priceColumn
];

const graphicsCardTableConfig: PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Chipset',
        propertyPath: 'specifications.chip',
    },
    {
        label: 'Memory',
        propertyPath: 'specifications.vram',
        formatter: value => `${value} GB`
    },
    {
        label: 'GPU Clock',
        propertyPath: 'specifications.gpuClock',
        formatter: value => `${value} Mhz`
    },
    {
        label: 'Memory Clock',
        propertyPath: 'specifications.memoryClock',
        formatter: value => `${value} Mhz`
    },
    {
        label: 'Bus Width',
        propertyPath: 'specifications.memoryBusWidth'
    },
    priceColumn
];

const motherboardTableConfig: PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Manufacturer',
        propertyPath: 'specifications.manufacturer'
    },
    {
        label: 'Chipset',
        propertyPath: 'specifications.chipset'
    },
    {
        label: 'Memory Type',
        propertyPath: 'specifications.memoryType'
    },
    priceColumn
];

const memoryTableConfig: PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Capacity',
        propertyPath: 'specifications.capacity'
    },
    {
        label: 'Modules',
        propertyPath: 'specifications.sticks'
    },
    {
        label: 'Type',
        propertyPath: 'specifications.type'
    },
    {
        label: 'Speed',
        propertyPath: 'specifications.speed',
        formatter: (value) => `${value} Mhz`
    },
    priceColumn
];

const ssdTableConfig: PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Capacity',
        propertyPath: 'specifications.capacity',
        formatter: (value) => (value as number) > 1000 ? `${Math.round((value as number) / 1000)} TB` : `${value} GB`
    },
    {
        label: 'Type',
        propertyPath: 'specifications.connectorType',
    },
    {
        label: 'NVMe',
        propertyPath: 'specifications.isNVMe',
        formatter: (value) => value ? 'Yes' : 'No'
    },
    priceColumn
];

const desktopHDDTableConfig : PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Capacity',
        propertyPath: 'specifications.capacity',
        formatter: (value) => (value as number) > 1000 ? `${Math.round((value as number) / 1000)} TB` : `${value} GB`
    },
    {
        label: 'RPM',
        propertyPath: 'specifications.rpm'
    },
    priceColumn
];

const powerSupplyUnitTableConfig : PCPartTableColumn[] = [
    nameColumn,
    {
        label: 'Wattage (W)',
        propertyPath: 'specifications.wattage'
    },
    priceColumn
];

export const pcPartTableConfigurations : {[pcPartType: string] : PCPartTableColumn[]} = {
    [PCPartType.Any] : [nameColumn, priceColumn],
    [PCPartType.Processor] : processorTableConfig,
    [PCPartType.GraphicsCard] : graphicsCardTableConfig,
    [PCPartType.Motherboard] : motherboardTableConfig,
    [PCPartType.Memory] : memoryTableConfig,
    [PCPartType.SSD] : ssdTableConfig,
    [PCPartType.DesktopHDD] : desktopHDDTableConfig,
    [PCPartType.PowerSupplyUnit] : powerSupplyUnitTableConfig,
};