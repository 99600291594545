import { Box, Select, MenuItem, IconButton, Divider, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PCPartType } from '../../shared/models/pcPartType';
import SearchIcon from '@mui/icons-material/Search';
import QUERY_PARAM_KEYS from '../models/queryParamKeys';
import { useAppDispatch } from '../../../app/hooks';
import { loadPCPartsAsync } from '../../../app/app.slice';
import { SortBy, ViewMode } from './PCPartBrowsingOptionsToolbar';
import { useSelectQueryParam } from '../../../app/customHooks';

export default function PCPartsSearchBar() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const pcPartType = useSelectQueryParam<PCPartType>(QUERY_PARAM_KEYS.pcPartType, PCPartType.Any);
    const viewMode = useSelectQueryParam<ViewMode>(QUERY_PARAM_KEYS.viewMode, ViewMode.Grid);
    const sortBy = useSelectQueryParam<SortBy>(QUERY_PARAM_KEYS.sortBy, SortBy.PriceAsc);

    const [tempPCPartType, setTempPCPartType] = useState(pcPartType);
    const [tempSearchPhrase, setTempSearchPhrase] = useState(useSelectQueryParam(QUERY_PARAM_KEYS.phrase, ''));

    // Load pc parts on initial page load
    useEffect(() => {
        dispatch(loadPCPartsAsync({ pcPartType: tempPCPartType, searchPhrase: tempSearchPhrase }));
    }, []);

    const handleSearchClick = () => {
        const updatedQueryParams = {
            [QUERY_PARAM_KEYS.pcPartType]: tempPCPartType,
            [QUERY_PARAM_KEYS.phrase]: tempSearchPhrase,
            [QUERY_PARAM_KEYS.viewMode]: viewMode,
            [QUERY_PARAM_KEYS.sortBy]: sortBy
        };

        const actionArgs = { pcPartType: tempPCPartType, searchPhrase: tempSearchPhrase };
        dispatch(loadPCPartsAsync(actionArgs)).then(() => {
            navigate({
                pathname: 'browse',
                search: createSearchParams(updatedQueryParams).toString(),
            }, { replace: true });
        });
    };

    const searchBtnRef = useRef<HTMLButtonElement>(null);

    return (
        <Box
            p={1}
            display='flex'
            alignItems='center'
            maxWidth={800}
            borderRadius={9}
            sx={{
                backgroundColor: 'white',
            }}
        >
            <Box mx={1}>
                <Select
                    sx={{
                        backgroundColor: 'transparent'
                    }}
                    variant='standard'
                    value={tempPCPartType}
                    disableUnderline
                    onChange={s => setTempPCPartType(s.target.value as PCPartType)}
                >
                    <MenuItem value={PCPartType.Any}>All</MenuItem>
                    <MenuItem value={PCPartType.Processor}>Processor</MenuItem>
                    <MenuItem value={PCPartType.GraphicsCard}>Graphics Card</MenuItem>
                    <MenuItem value={PCPartType.Motherboard}>Motherboard</MenuItem>
                    <MenuItem value={PCPartType.Memory}>Memory</MenuItem>
                    <MenuItem value={PCPartType.SSD}>SSD</MenuItem>
                    <MenuItem value={PCPartType.DesktopHDD}>HDD</MenuItem>
                    <MenuItem value={PCPartType.PowerSupplyUnit}>Power Supply Unit</MenuItem>
                </Select>
            </Box>
            <Divider orientation='vertical' flexItem />
            <TextField
                variant='standard'
                placeholder='Find Parts'
                fullWidth
                value={tempSearchPhrase}
                sx={{ paddingLeft: '15px' }}
                onChange={(e) => setTempSearchPhrase(e.target.value)}
                InputProps={{
                    disableUnderline: true,
                    endAdornment: <IconButton ref={searchBtnRef} onClick={handleSearchClick}>
                        <SearchIcon></SearchIcon>
                    </IconButton>,
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        searchBtnRef?.current?.click();
                    }
                }}
            >
            </TextField>
        </Box >
    );
}