import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ClientSidePCPartFilterCollection } from '../features/pcpart-filtering/models/clientSidePCPartFilterCollection';

export type AppQueryParams = {
    [key: string]: string
}

export const useAppQueryParams = (): [AppQueryParams, (newQueryParams: AppQueryParams) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();
    const appQueryParams = useMemo(() => Object.fromEntries(searchParams.entries()), [searchParams]);

    const updateQueryParams = (newQueryParams: AppQueryParams) => {
        const updatedSearchParams = searchParams;

        Object.keys(newQueryParams).forEach(k => {
            if (newQueryParams[k] === '') {
                updatedSearchParams.delete(k);
            }
            else {
                updatedSearchParams.set(k, newQueryParams[k]);
            }
        });
        setSearchParams(updatedSearchParams, { replace: true });
    };

    return [appQueryParams, updateQueryParams];
};

export const useSelectQueryParam = <T>(key: string, defaultValue: T) => {
    const [queryParams] = useAppQueryParams();
    return (queryParams[key] ?? defaultValue) as T;
};

export const usePCPartFilters = () : [ClientSidePCPartFilterCollection, (key: string, value: string[]) => void ] => {
    const [queryParams, updateQueryParams] = useAppQueryParams();

    // const [filterCollection, setFilterCollection] = useState(ClientSidePCPartFilterCollection.fromAppQueryParams(queryParams));
    const filterCollection = useMemo(() => ClientSidePCPartFilterCollection.fromAppQueryParams(queryParams), [queryParams]);
    const updateFilter = (key: string, values: Array<string>) => {
        updateQueryParams({
            [key]: values.join(',')
        });
    };

    return [filterCollection, updateFilter];
};