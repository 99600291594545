import { useMemo } from 'react';
import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import FilterAreaProps from './filterAreaProps';
import { selectDistinct } from '../../../common/helpers';
import FILTER_KEYS from '../models/pcPartFilterKeys';

export default function ManufacturerFilter(props: FilterAreaProps) {
    const { pcParts, filters, onFilterUpdate } = props;
    const manufacturers = useMemo(() => selectDistinct(pcParts.map(p => p.specifications.manufacturer)), [pcParts]);

    return (
        <FilterCheckboxesGroup
            hasAnyValueCheckbox={true}
            referenceValues={manufacturers}
            selectedValues={filters.getValues(FILTER_KEYS.manufacturer)}
            onSelectionChange={values => onFilterUpdate(FILTER_KEYS.manufacturer, values)}
        />
    );
}