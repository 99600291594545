import { Alert, Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import pcPartsAPI from '../pcpart-browsing/pcPartsAPI';
import { PCPart, PCPartStoreListing } from '../shared/models/pcPart';
import PCPartSpecificationDisplayConfig from './models/PCPartSpecificationsDisplayConfig';
import specificationsDisplayConfigs from './models/PCPartTypeSpecificationDisplayConfig';
import { buildImageUrl } from '../../common/helpers';

export default function PCPartDetailsPage() {
    const { id } = useParams();
    const [pcPart, setPCPart] = useState<PCPart | null>(null);

    useEffect(() => {
        if (id) {
            pcPartsAPI.getPCPart(id).then(setPCPart).catch(err => console.dir(err));
        }
    }, [id]);

    if (!id) {
        return (<Alert color="error">PCPart not found</Alert>);
    }

    if (!pcPart) {
        return (<Typography>Loading .....</Typography>);
    }

    const pcPartSpecificationDisplayConfig = specificationsDisplayConfigs[pcPart.pcPartType];

    return (
        <Grid
            container
            pl={1}
            mt={1}
            maxWidth={900}
            sx={{
                marginLeft: 'auto', marginRight: 'auto'
            }}
        >
            <Grid item xs={12}>
                {/* Header  */}
                <Box>
                    <Typography variant='h4'>{pcPart.name}</Typography>
                    <Typography variant='subtitle1'>{pcPart.pcPartType}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                {/* Main content  */}
                <Grid mt={2} container>
                    {/* Image Box */}
                    <Grid item xs={12} sm={4} md={5} xl={3}>
                        <Box display='flex' justifyContent='center'>
                            <img src={buildImageUrl(pcPart.imageId, 'reg')} width={250} />
                        </Box>
                    </Grid>
                    {/* Specifications Table */}
                    <Grid item xs={12} sm={4} md={6}>
                        <Box
                            ml={1}
                            mt={1}
                        >
                            <Typography variant='h6'>Specifications</Typography>
                            <Box mt={1}>
                                <PCPartSpecificationsList
                                    pcPart={pcPart}
                                    displayConfigs={pcPartSpecificationDisplayConfig}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {/* Price Table  */}
            <Grid item xs={12}>
                <Box
                    mt={3}
                    px={1}
                    maxWidth={800}
                >
                    <Typography variant="h6">Prices</Typography>
                    <Box mt={1}>
                        <PCPartPricingTable pcPart={pcPart} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

function PCPartSpecificationsList(props: PCPartSpecificationsListProps) {
    const { pcPart, displayConfigs } = props;
    return (
        <Box ml={1}>
            <ul
                style={{
                    listStyle: 'none',
                    margin: '0px',
                    padding: '0px'
                }}
            >
                {
                    displayConfigs.map(config =>
                        <li
                            key={config.propertyPath}
                            style={{ display: 'flex', marginTop: '3px' }}
                        >
                            <Typography
                                variant='body2'
                                fontWeight={500}
                            >
                                {config.label}:
                            </Typography>
                            <Typography
                                variant='body2'
                                ml={1}
                            >
                                {
                                    renderSpecificationValue({ pcPart: pcPart, config: config })
                                }
                            </Typography>
                        </li>
                    )
                }
            </ul>

        </Box>
    );
}

function renderSpecificationValue(props: { pcPart: PCPart, config: PCPartSpecificationDisplayConfig }) {
    const { pcPart, config } = props;
    const value = walkPropertyPath(config.propertyPath, pcPart);
    if (!value) {
        return 'N/A';
    }
    else {
        return config.formatter ? config.formatter(value) : value.toString();
    }
}

interface PCPartSpecificationsListProps {
    pcPart: PCPart,
    displayConfigs: PCPartSpecificationDisplayConfig[]
}

function PCPartPricingTable(props: { pcPart: PCPart }) {
    const { pcPart } = props;
    return (
        <TableContainer>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell align='left' sx={{textAlign: 'left'}}>Store</TableCell>
                        <TableCell align='left' sx={{textAlign: 'left'}}>Price</TableCell>
                        <TableCell align='left' sx={{textAlign: 'left'}}>Availability</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        pcPart.pcPartStoreListings.map(listing =>
                            <TableRow key={listing.pcPartStore}>
                                <TableCell>{listing.pcPartStore}</TableCell>
                                <TableCell>{listing.price.toLocaleString()} </TableCell>
                                <TableCell>{renderAvailabilityText(listing)}</TableCell>
                                <TableCell>
                                    <Button
                                        href={listing.url}
                                        target="_blank"
                                        rel="noreferrer"
                                        variant='outlined'
                                        sx={{ color: 'black', borderColor: 'black' }}
                                    >
                                        Open
                                    </Button>
                                </TableCell>
                            </TableRow>)
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function renderAvailabilityText(pcPart: PCPartStoreListing) {
    const availability = pcPart.inventoryStatus;
    let text = '-';

    switch(availability) {
        case 'InStock': {
            text = 'In Stock';
            break;
        }
        case 'InStockSystemsOnly': {
            text = 'In Stock';
            break;
        }
        case 'OutOfStock': {
            text = 'Out of Stock';
            break;
        }
        case 'NotSpecified': {
            text = '-';
            break;
        }
        case 'PreOrder': {
            text = 'Pre Order';
            break;
        }
        default: {
            text = '-';
        }
    }
    return text;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function walkPropertyPath(propertyPath: string, entity: any): unknown {
    if (!propertyPath || !entity) {
        return null;
    }

    const dotIndex = propertyPath.indexOf('.');
    if (dotIndex <= 0) {
        return (entity[propertyPath]) ?? null;
    }
    else {
        const currentSegment = propertyPath.substring(0, dotIndex);
        const rest = propertyPath.substring(dotIndex + 1);
        return walkPropertyPath(rest, entity[currentSegment]);
    }
}
