import axios from 'axios';
import { Configuration } from '../../configuration';
import { PCPart } from '../shared/models/pcPart';
import { PCPartType } from '../shared/models/pcPartType';

const baseUrl = `${Configuration.API_Base_URL}/pc-parts`;

const searchPCParts = (pcPartType: PCPartType, searchPhrase: string | null) => {
    const normalizedPhrase = searchPhrase?.toLowerCase().split(' ').filter(token => token !== '').map(token => token.trim()).join(' ');
    const normalizedPCPartType = pcPartType === PCPartType.Any ? PCPartType.Null : pcPartType;
    return axios.get<Array<PCPart>>(baseUrl, { params: { type: normalizedPCPartType, phrase: normalizedPhrase } }).then(r => r.data);
};

const getPCPart = (id: string) => {
    return axios.get<PCPart>(`${baseUrl}/${id}`).then(r => r.data);
};

export const pcPartsAPI = {
    searchPCParts,
    getPCPart
};

export default pcPartsAPI;