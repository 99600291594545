import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PCPartsSearchBar from './features/pcpart-browsing/components/PCPartsSearchBar';
import PCPartsFilter from './features/pcpart-browsing/components/PCPartsFilter';

export default function App() {
    const navigate = useNavigate();
    const location = useLocation();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box
            sx={{
                textAlign: 'center'
            }}
            mr={2}
        >
            <Stack direction='row'>
                <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    edge="start"
                    onClick={() => setMobileOpen(false)}
                    sx={{ ml: 2, display: { md: 'none' } }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography flexGrow={1} textAlign='center' variant="h4" m={1}>
                    PCPARTS.LK
                </Typography>
            </Stack>
            <Divider />
            {
                // Only show filters in the drawer if on browsing page
                // If more pages get added in addition to the simple browse and details pages this will need to be changed to a better design
                location.pathname.startsWith('/browse') && <PCPartsFilter />
            }
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component='nav' sx={{ backgroundColor: '#000000', p: { xs: '3px', sm: 0 } }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h3"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('')}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        PCPARTS.LK
                    </Typography>
                    <Box
                        flexGrow={1}
                        sx={{
                            ml: {
                                md: 5,
                                lg: 20
                            }
                        }}
                    >
                        <PCPartsSearchBar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                width='100%'
                height='100%'
            >
                <Box
                    mt={10}
                    minHeight='75vh'
                    component="main"
                >
                    <Box component="nav">
                        <Drawer
                            variant="temporary"
                            transitionDuration={150}
                            open={mobileOpen}
                            ModalProps={{
                                keepMounted: true
                            }}
                            sx={{
                                display: { xs: 'block', lg: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    <Box 
                        p={0} 
                        m={0}
                    >
                        <Outlet />
                    </Box>
                </Box>
                <Box>
                    <AppFooter />
                </Box>
            </Box>
        </Box>
    );
}

const AppFooter = () => (
    <footer>
        <Box
            mt={5}
            height={80}
        >
            <Divider />
            <Box
                mt={2}
                mx={4}
                display='flex'
                flexDirection='row'
            >
                <Typography
                    variant='h4'
                >
                    PCPARTS.LK
                    <sup style={{ fontSize: 'small' }}>
                        (alpha)
                    </sup>
                </Typography>
                <Box flexGrow={1}>
                    <span></span>
                </Box>
            </Box>
            <Box
                mt={2}
                mb={1}
                width='100%'
                textAlign='center'
            >
                <Typography
                    variant='body2'
                >
                    The information and pictures presented on this website belong to their respective online stores
                </Typography>
            </Box>
        </Box>
    </footer>
);
