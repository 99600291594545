import { Box, Typography, Skeleton, Divider, List, ListItem } from '@mui/material';
import { PCPart } from '../../shared/models/pcPart';
import InStockIcon from '../../../assets/images/in-stock.png';
import OutOfStockIcon from '../../../assets/images/out-of-stock.png';
import { useMemo } from 'react';
import { buildImageUrl } from '../../../common/helpers';
import { getPCPartHighlights } from '../models/pcPartHighlightsConfiguration';

export interface PCPartsGridViewProps {
    pcParts: PCPart[];
    isLoading: boolean;
    onPCPartClicked: (pcPart: PCPart) => void;
}

export default function PCPartsGridView(props: PCPartsGridViewProps) {
    return (
        <>
            <Box
                sx={{
                    display: {
                        xs: 'none',
                        md: 'flex'
                    }
                }}
            >
                <StandardPCPartGrid {...props} />
            </Box>

            <Box
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none'
                    }
                }}
            >
                <MobilePCPartGrid {...props} />
            </Box>
        </>
    );
}

function StandardPCPartGrid(props: PCPartsGridViewProps) {
    const { pcParts, isLoading, onPCPartClicked } = props;

    return (
        <Box
            display='flex'
            flexWrap='wrap'
        >
            {
                isLoading
                    ? Array.from(Array(20).keys())
                        .map(i =>
                            <Box
                                key={i}
                                m={1}
                            >
                                <Skeleton variant='rectangular' height={290} width={170} />
                            </Box>
                        )
                    :
                    pcParts.map(p =>
                        <Box
                            key={p.id}
                            m={2}
                        >
                            <StandardPCPartCard pcPart={p} onClick={onPCPartClicked} />
                        </Box>
                    )

            }

        </Box>
    );
}

function MobilePCPartGrid(props: PCPartsGridViewProps) {
    const { pcParts, isLoading, onPCPartClicked } = props;
    return (
        <List>
            {
                isLoading
                    ? Array.from(Array(20).keys())
                        .map(i =>
                            <ListItem key={i}>
                                <Skeleton
                                    variant='rectangular'
                                    width='100%'
                                    height={80}
                                />
                            </ListItem>
                        )
                    :
                    pcParts.map(p =>
                        <ListItem
                            key={p.id}
                            component='div'
                            sx={{ p: 0 }}
                        >
                            <MobilePCPartCard pcPart={p} onClick={onPCPartClicked} />
                        </ListItem>
                    )
            }
        </List>
    );
}

interface PCPartCardProps {
    pcPart: PCPart;
    onClick: (pcPart: PCPart) => void;
}

function StandardPCPartCard(props: PCPartCardProps) {
    const { pcPart, onClick } = props;

    const imageUrl = useMemo(() => buildImageUrl(pcPart.imageId, 'thumb-pc'), [pcPart]);
    const pricings = useMemo(() => [...pcPart.pcPartStoreListings].sort((a, b) => a.pcPartStore.localeCompare(b.pcPartStore)), [pcPart]);

    return (
        <div onClick={() => onClick(pcPart)}>
            <Box
                height={260}
                width={160}
                display='flex'
                flexDirection='column'
            >
                <img
                    src={imageUrl}
                    width={130}
                    height={130}
                    alt='product image'
                    style={{
                        objectFit: 'scale-down',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                />
                <Typography
                    variant="body1"
                    mt={1}
                    textAlign='center'
                    sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                    }}
                >
                    {props.pcPart.name}
                </Typography>
                
                <Box
                    width={160}
                >
                    <table style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                        <thead>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                pricings.map(l =>
                                    <tr 
                                        key={l.url}
                                        style={{
                                            lineHeight: '1.0'
                                        }}
                                    >
                                        <td style={{}}>
                                            <Typography variant='body2'>{l.pcPartStore}</Typography>
                                        </td>
                                        <td style={{ paddingLeft: '2px', paddingRight: '2px' }}>
                                            <Typography variant='body2'>{l.price.toLocaleString()}</Typography>
                                        </td>
                                        <td style={{ paddingLeft: '2px' }}>
                                            <img
                                                style={{ maxWidth: '15px' }}
                                                src={(l.inventoryStatus === 'InStock' || l.inventoryStatus === 'InStockSystemsOnly') ? InStockIcon : OutOfStockIcon}
                                            />
                                        </td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </Box>
            </Box>
        </div>
    );
}

function MobilePCPartCard(props: PCPartCardProps) {
    const { pcPart, onClick } = props;

    const imageUrl = useMemo(() => buildImageUrl(pcPart.imageId, 'thumb-mob'), [pcPart]);
    const highlights = useMemo(() => getPCPartHighlights(pcPart).filter(h => h.value), [pcPart]);
    const pricings = useMemo(() => [...pcPart.pcPartStoreListings].sort((a, b) => a.pcPartStore.localeCompare(b.pcPartStore)), [pcPart]);

    return (
        <Box
            width='100%'
            sx={{ cursor: 'pointer' }}
            onClick={() => onClick(pcPart)}
            px={1}
            mb={1}
        >
            <Typography variant='body1'>{pcPart.name}</Typography>
            <Box
                py={1}
                display='flex'
                flexDirection='row'
            >
                <img
                    src={imageUrl}
                    width={75}
                    height={75}
                    alt='product image'
                    style={{
                        objectFit: 'scale-down'
                    }}
                />
                <Box
                    ml={1}
                    flexGrow={1}
                >
                    {
                        highlights.map(h =>
                            <Typography
                                key={`${pcPart.id}::${h.key}`}
                                variant='body2'
                            >
                                {h.key} - {h.value}
                            </Typography>)
                    }
                </Box>
                <ul
                    style={{ listStyle: 'none' }}
                >
                    {
                        pricings.map(p =>
                            <li key={p.pcPartStore}>
                                <Typography variant='body2'>
                                    {p.pcPartStore} - {p.price.toLocaleString()} LKR
                                </Typography>
                            </li>
                        )
                    }
                </ul>
            </Box>
            <Divider />
        </Box>
    );
}
