import { Configuration } from '../configuration';

/* eslint-disable @typescript-eslint/no-explicit-any */
export function selectDistinct<T>(items: Array<T>) {
    return Array.from(new Set(items));
}

export function getByPropertyPath<T>(entity: any, propertyPath: string): T | null {
    if (!propertyPath || !entity) {
        return null;
    }

    const dotIndex = propertyPath.indexOf('.');
    if (dotIndex <= 0) {
        return (entity[propertyPath]) ?? null;
    }
    else {
        const currentSegment = propertyPath.substring(0, dotIndex);
        const rest = propertyPath.substring(dotIndex + 1);
        return getByPropertyPath(entity[currentSegment], rest);
    }
}

export function buildImageUrl(imageId: string, variant: string) {
    return `${Configuration.IMAGE_BASE_PATH}/${imageId}_${variant}.jpg`;
}