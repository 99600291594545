import { PCPartType } from '../../shared/models/pcPartType';
import PCPartSpecificationDisplayConfig from './PCPartSpecificationsDisplayConfig';

const motherboardSpecificationsConfig: PCPartSpecificationDisplayConfig[] = [
    {
        label: 'Chipset',
        propertyPath: 'specifications.chipset'
    },
    {
        label: 'Memory Type',
        propertyPath: 'specifications.memoryType'
    },
    {
        label: 'Socket',
        propertyPath: 'specifications.memoryType',
        formatter: () => 'Unknown'
    }
];

const processorSpecificationConfig: PCPartSpecificationDisplayConfig[] = [
    {
        propertyPath: 'specifications.coreCount',
        label: 'Cores'
    },
    {
        propertyPath: 'specifications.baseClock',
        label: 'Base Clock',
        formatter: (val) => `${val} GHz`
    },
    {
        propertyPath: 'specifications.boostClock',
        label: 'Boost Clock',
        formatter: (val) => `${val} GHz`
    },
    {
        propertyPath: 'specifications.l3Cache',
        label: 'L3 Cache',
        formatter: (val) => `${val} MB`
    },
    {
        propertyPath: 'specifications.tdp',
        label: 'TDP',
        formatter: (val) => `${val} W`
    },
];

const graphicsCardSpecificationConfig: PCPartSpecificationDisplayConfig[] = [
    {
        label: 'Chipset',
        propertyPath: 'specifications.chip',
    },
    {
        label: 'Memory',
        propertyPath: 'specifications.vram',
        formatter: value => `${value} GB`
    },
    {
        label: 'GPU Clock',
        propertyPath: 'specifications.gpuClock',
        formatter: value => `${value} Mhz`
    },
    {
        label: 'Memory Clock',
        propertyPath: 'specifications.memoryClock',
        formatter: value => `${value} Mhz`
    },
    {
        label: 'Bus Width',
        propertyPath: 'specifications.memoryBusWidth'
    },
];

const ssdSpecificationsConfig: PCPartSpecificationDisplayConfig[] = [
    {
        label: 'Capacity',
        propertyPath: 'specifications.capacity',
        formatter: value => (value as number) > 1000 ? `${value}TB` : `${value}GB`
    },
    {
        label: 'Form Factor',
        propertyPath: 'specifications.formFactor',
        formatter: (value) => value === 'SATA' ? `${value} 2.5"` : `${value}`
    },
    {
        label: 'NVMe',
        propertyPath: 'specifications.isNVMe',
        formatter: value => value ? 'Yes' : 'False'
    }
];

const memorySpecificationsConfig: PCPartSpecificationDisplayConfig[] = [
    {
        label: 'Type',
        propertyPath: 'specifications.type'
    },
    {
        label: 'Speed',
        propertyPath: 'specifications.speed',
        formatter: (value) => `${value} MHz`
    },
    {
        label: 'Modules',
        propertyPath: 'specifications.modules'
    }
];

const desktopHDDSpecificationConfig: PCPartSpecificationDisplayConfig[] = [
    {
        label: 'Model',
        propertyPath: 'model'
    },
    {
        label: 'Capacity',
        propertyPath: 'specifications.capacity',
        formatter: (value) => `${value} GB`
    },
    {
        label: 'Cache',
        propertyPath: 'specifications.cache',
        formatter: (value) => `${value} MB`
    },
    {
        label: 'RPM',
        propertyPath: 'specifications.rpm',
    }
];

const powerSupplyUnitSpecificationConfig: PCPartSpecificationDisplayConfig[] = [
    {
        label: 'Wattage',
        propertyPath: 'specifications.wattage'
    },
    {
        label: 'Rating',
        propertyPath: 'specifications.rating'
    },
    {
        label: 'Modular?',
        propertyPath: 'specifications.isModular'
    }
];

const specificationsDisplayConfigs: { [pcPartType: string]: PCPartSpecificationDisplayConfig[] } = {
    [PCPartType.Processor]: processorSpecificationConfig,
    [PCPartType.GraphicsCard]: graphicsCardSpecificationConfig,
    [PCPartType.Motherboard]: motherboardSpecificationsConfig,
    [PCPartType.Memory]: memorySpecificationsConfig,
    [PCPartType.SSD]: ssdSpecificationsConfig,
    [PCPartType.DesktopHDD]: desktopHDDSpecificationConfig,
    [PCPartType.PowerSupplyUnit]: powerSupplyUnitSpecificationConfig
};

export default specificationsDisplayConfigs;