import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import { useMemo } from 'react';
import FilterAreaProps from './filterAreaProps';
import { selectDistinct } from '../../../common/helpers';
import FILTER_KEYS from '../models/pcPartFilterKeys';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';

const MEMORY_TYPES = ['DDR5', 'DDR4', 'DDR3'];

export default function MotherboardFilter(props: FilterAreaProps) {
    const { pcParts, filters, onFilterUpdate } = props;

    const availableChipsets = useMemo(() => selectDistinct(pcParts.map(p => p.specifications.chipset)), [pcParts]);

    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Chipset'>
                <FilterCheckboxesGroup
                    referenceValues={availableChipsets}
                    selectedValues={filters.getValues(FILTER_KEYS.chipset)}
                    onSelectionChange={values => onFilterUpdate(FILTER_KEYS.chipset, values)}
                />
            </FilterSection>
            <FilterSection title='Memory Type'>
                <FilterCheckboxesGroup
                    referenceValues={MEMORY_TYPES}
                    selectedValues={filters.getValues(FILTER_KEYS.memoryType)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.memoryType, values)}
                />
            </FilterSection>
        </FilterAreaWithCollapsibleChildren>);
}