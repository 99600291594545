import { PCPart } from '../../shared/models/pcPart';

export interface PCPartHighlightConfig {
    label: string;
    specificationKey: string;
    suffix?: string;
    valueFormatter?: (value: unknown) => string
}

export const pcPartHighlightConfiguration: { [pcPartType: string]: PCPartHighlightConfig[] } = {
    'Processor': [
        { label: 'Cores', specificationKey: 'coreCount' },
        { label: 'Base', specificationKey: 'baseClock', suffix: 'GHz' },
        { label: 'Boost', specificationKey: 'boostClock', suffix: 'GHz' },
        { label: 'TDP', specificationKey: 'tdp', suffix: 'W' }
    ],
    'GraphicsCard': [
        { label: 'Memory', specificationKey: 'vram', suffix: 'GB' },
        {
            label: 'Clock',
            specificationKey: 'gpuClock',
            valueFormatter: (clock) => (clock as number / 1000).toString(),
            suffix: 'GHz'
        },
    ],
    'SSD': [
        {
            label: 'Capacity',
            specificationKey: 'capacity',
            valueFormatter: (capacity) => `${capacity} ${capacity as number < 100 ? 'TB' : 'GB'}`
        }
    ]
};

export function getPCPartHighlights(pcPart: PCPart) {
    const config = pcPartHighlightConfiguration[pcPart.pcPartType];
    if (!config) {
        return [];
    }
    return config.map(c => ({ key: c.label, value: extractValue(pcPart, c) }));
}

function extractValue(pcPart: PCPart, config: PCPartHighlightConfig) {
    const val = pcPart.specifications[config.specificationKey];
    if (!val) {
        return '';
    }
    else {
        return `${config.valueFormatter ? config.valueFormatter(val) : val} ${config.suffix ?? ''}`.trimEnd();
    }
}