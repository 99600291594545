import { Grid, Box, Skeleton, Stack, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PCPart } from '../shared/models/pcPart';
import { PCPartType } from '../shared/models/pcPartType';
import PCPartBrowsingOptionsToolbar, { ViewMode, SortBy } from './components/PCPartBrowsingOptionsToolbar';
import PCPartsFilter from './components/PCPartsFilter';
import PCPartsGridView from './components/PCPartsGridView';
import QUERY_PARAM_KEYS from './models/queryParamKeys';
import PCPartsTableView from './components/PCPartsTableView';
import { useAppSelector } from '../../app/hooks';
import { useAppQueryParams, useSelectQueryParam } from '../../app/customHooks';
import { useMemo } from 'react';
import { ClientSidePCPartFilterCollection } from '../pcpart-filtering/models/clientSidePCPartFilterCollection';

export default function PCPartsBrowsingPage() {
    const navigate = useNavigate();

    const [queryParams, updateQueryParams] = useAppQueryParams();

    const allPCParts : PCPart[] = useAppSelector(s => s.app.pcParts);
    const isLoading = useAppSelector(s => s.app.isLoading);

    const pcPartType = useSelectQueryParam<PCPartType>(QUERY_PARAM_KEYS.pcPartType, PCPartType.Any);
    const viewMode = useSelectQueryParam<ViewMode>(QUERY_PARAM_KEYS.viewMode, ViewMode.Grid);
    const sortBy = useSelectQueryParam<SortBy>(QUERY_PARAM_KEYS.sortBy, SortBy.PriceAsc);

    const filters = useMemo(() => ClientSidePCPartFilterCollection.fromAppQueryParams(queryParams), [allPCParts, queryParams]);

    const filteredPCParts = useMemo(() => filters.applyTo(allPCParts), [filters]);
    const sortedPCParts = useMemo(() => applySortBy(filteredPCParts, sortBy), [filteredPCParts, sortBy]);

    const handleViewOptionChanges = (key: string, value: string) => {
        updateQueryParams({
            [key]: value
        });
    };

    return (
        <>
            {
                allPCParts.length > 0
                    ? (
                        <Grid container p={0} m={0}>
                            <Grid item md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Box >
                                    <PCPartsFilter />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Box sx={{
                                    ml: {
                                        md: 2
                                    }
                                }}>
                                    <Box ml={1}>
                                        {
                                            isLoading
                                                ? <Skeleton height={70} sx={{ mx: 1 }} />
                                                : <Stack direction='row'>
                                                    <PCPartBrowsingOptionsToolbar
                                                        sortBy={sortBy}
                                                        viewMode={viewMode}
                                                        onSortByChanged={val => handleViewOptionChanges(QUERY_PARAM_KEYS.sortBy, val.toString())}
                                                        onViewModelChanged={val => handleViewOptionChanges(QUERY_PARAM_KEYS.viewMode, val.toString())}
                                                    />
                                                </Stack>
                                        }
                                    </Box>
                                    <Box>
                                        {
                                            viewMode === ViewMode.Grid
                                                ? <PCPartsGridView
                                                    pcParts={sortedPCParts}
                                                    onPCPartClicked={p => navigate(`/details/${p.id}`)}
                                                    isLoading={isLoading}
                                                />
                                                // : <SearchResultsGridView pcParts={applySortBy(filteredPCParts, sortBy)} gridConfig={getPCPartGridConfig(pcPartType)} />)
                                                : <PCPartsTableView
                                                    pcParts={filteredPCParts}
                                                    pcPartType={pcPartType}
                                                    isLoading={isLoading}
                                                    onPCPartClicked={(part) => navigate(`/details/${part.id}`)}
                                                />
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid >
                    )
                    : !isLoading &&
                    <Box
                        ml='auto'
                        mr='auto'
                        mt={5}
                        height='100%'
                        maxWidth={600}
                    >
                        <Alert severity="info">No parts found!</Alert>
                    </Box>
            }

        </>
    );
}

function applySortBy(pcParts: PCPart[], sortBy: SortBy) {
    if (!pcParts || pcParts.length === 0) {
        return [];
    }

    let pcPartsCopy: Array<PCPart>;
    switch (sortBy) {
        case SortBy.Alphabatic: {
            pcPartsCopy = pcParts.slice().sort((a, b) => a.name.localeCompare(b.name));
            break;
        }
        case SortBy.PriceAsc: {
            pcPartsCopy = pcParts.slice().sort((a, b) => a.averagePrice - b.averagePrice);
            break;
        }
        case SortBy.PriceDesc: {
            pcPartsCopy = pcParts.slice().sort((a, b) => b.averagePrice - a.averagePrice);
            break;
        }
    }

    return pcPartsCopy;
}
