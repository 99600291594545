import { PCPart } from '../../shared/models/pcPart';
import { PCPartType } from '../../shared/models/pcPartType';
import PCPartsTable from './PCPartsTable';
import { pcPartTableConfigurations } from '../models/pcPartTableConfigurations';
import { Skeleton } from '@mui/material';

export interface PCPartsTableViewProps {
    pcParts: PCPart[];
    pcPartType: PCPartType;
    isLoading: boolean;
    onPCPartClicked: (pcPart: PCPart) => void;
}

export default function PCPartsTableView(props: PCPartsTableViewProps) {
    const { pcPartType, pcParts, isLoading, onPCPartClicked } = props;

    return (
        isLoading ? <Skeleton/> : <PCPartsTable
            tableHeaderColumns={pcPartTableConfigurations[pcPartType]}
            pcParts={pcParts}
            onRowClicked={onPCPartClicked}
        />
    );
}