import FILTER_KEYS from '../models/pcPartFilterKeys';
import FilterAreaProps from './filterAreaProps';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';
import FilterCheckboxesGroup from './FilterCheckboxesGroup';

const HDD_CAPACITIES = ['250GB', '500GB', '1TB', '2TB', '4TB', '6TB', '8TB', '10TB', '12TB', '14TB'];

export default function DesktopHDDFilter(props: FilterAreaProps) {
    const { filters, onFilterUpdate } = props;
    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Capacity'>
                <FilterCheckboxesGroup {...props}
                    referenceValues={HDD_CAPACITIES}
                    selectedValues={filters.getValues(FILTER_KEYS.capacity)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.capacity, values)}
                />
            </FilterSection>
            <FilterSection title=''>
                <></>
            </FilterSection>
        </FilterAreaWithCollapsibleChildren>
    );
}