import { Stack, Select, MenuItem, Box, Typography } from '@mui/material';

export enum ViewMode {
    Grid = 'Grid',
    Table = 'Table'
}

export enum SortBy {
    PriceAsc = 'PriceAsc',
    PriceDesc = 'PriceDesc',
    Alphabatic = 'Alphabatic',
}

export interface PCPartViewerOptionsProps {
    sortBy: SortBy;
    viewMode: ViewMode;
    onSortByChanged: (sortBy: SortBy) => void;
    onViewModelChanged: (viewMode: ViewMode) => void;
}

export default function PCPartBrowsingOptionsToolbar(props: PCPartViewerOptionsProps) {
    const { viewMode, sortBy, onSortByChanged, onViewModelChanged } = props;

    return (
        <Stack
            direction='row'
            spacing={1}
            marginY={1}
        >
            <Box
                display='flex'
                sx={{
                    display: {
                        xs: 'none',
                        md: 'inherit'
                    }
                }}
            >
                <Typography
                    mt={1}
                    mr={1}
                    variant='body1'
                >
                    View:
                </Typography>
                <Select
                    variant='outlined'
                    size='small'
                    value={viewMode}
                    onChange={e => onViewModelChanged(e.target.value as ViewMode)}
                >
                    <MenuItem value={ViewMode.Grid}>Grid</MenuItem>
                    <MenuItem value={ViewMode.Table}>Table</MenuItem>
                </Select>
            </Box>
            {
                viewMode === ViewMode.Grid && (
                    <Box display='flex'>
                        <Typography
                            mt={1}
                            mr={1}
                            variant='body1'
                        >
                            Sort By:
                        </Typography>
                        <Select
                            size='small'
                            variant='outlined'
                            value={sortBy}
                            onChange={e => onSortByChanged(e.target.value as SortBy)}
                        >
                            <MenuItem value={SortBy.PriceAsc}>Price Low to High</MenuItem>
                            <MenuItem value={SortBy.PriceDesc}>Price High to Low</MenuItem>
                            <MenuItem value={SortBy.Alphabatic}>Alphabatic</MenuItem>
                        </Select>
                    </Box>
                )
            }
        </Stack>
    );
}