import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import FilterAreaProps from './filterAreaProps';
import FILTER_KEYS from '../models/pcPartFilterKeys';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';

const MEMORY_TYPES = ['DDR3', 'DDR4', 'DDR5'];
const MEMORY_SPEEDS = ['2400', '2666', '2933', '3000', '3200', '3600', '4000', '4400', '4800', '5200', '5600', '6000', '6200'];

export default function MemoryFilter(props: FilterAreaProps) {
    const { filters, onFilterUpdate } = props;
    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Type'>
                <FilterCheckboxesGroup
                    referenceValues={MEMORY_TYPES}
                    labelFormatter={(value) => value.toUpperCase()}
                    selectedValues={filters.getValues(FILTER_KEYS.memoryType)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.memoryType, values)}
                />
            </FilterSection>
            <FilterSection title='Speed'>
                <FilterCheckboxesGroup
                    referenceValues={MEMORY_SPEEDS}
                    labelFormatter={(value) => `${value} MHz`}
                    selectedValues={filters.getValues(FILTER_KEYS.memorySpeed)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.memorySpeed, values)}
                />
            </FilterSection>

        </FilterAreaWithCollapsibleChildren>
    );
}