import { PCPart } from '../../shared/models/pcPart';

export enum PCPartFilterOperation {
    ExactValueMatch,
    Numeric_GreaterThanOrEqualTo,
    Numeric_LessThanOrEqualTo,
    CustomPredicate,
}

export interface ClientSidePCPartFilter {
    pcPartProperty: keyof PCPart;
    pcPartsecondLevelProperty?: string
    values: Array<string>;
    queryParamKey: string;
    operation: PCPartFilterOperation
    predicate?: (pcPart: PCPart, filterValues: Array<string>) => boolean;
}