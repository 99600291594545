import { Box, Slider, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelectQueryParam } from '../../../app/customHooks';
import QUERY_PARAM_KEYS from '../../pcpart-browsing/models/queryParamKeys';
import { PCPartType } from '../../shared/models/pcPartType';

export type Range = {
    min: number;
    max: number;
};

export interface RangeSliderProps {
    min: number;
    max: number;
    step: number;
    value: Range;
    unitLabel?: string;
    onChange: (value: Range) => void;
}

export default function RangeSlider(props: RangeSliderProps) {
    const { value, min, max, step, unitLabel, onChange } = props;
    const [sliderState, setSliderState] = useState<Range>(value);

    // Temp fix
    const pcPartType = useSelectQueryParam<PCPartType>(QUERY_PARAM_KEYS.pcPartType, PCPartType.Any);
    useEffect(() => {
        setSliderState(value);
    }, [pcPartType]);

    return (
        <Box>
            <Slider
                valueLabelDisplay="on"
                min={min}
                max={max}
                step={step}
                size='small'
                sx={{ width: '80%' }}
                value={[sliderState.min, sliderState.max]}
                valueLabelFormat={(val) => <Typography variant='caption'>{val.toLocaleString()} {unitLabel}</Typography>}
                onChange={(_, num) => setSliderState({ min: (num as number[])[0], max: (num as number[])[1] })}
                onChangeCommitted={() => onChange(sliderState)}>
            </Slider>
        </Box>
    );
}