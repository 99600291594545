import { FormGroup, FormControlLabel, Checkbox, Typography, Box } from '@mui/material';

export interface CheckboxesGroupProps {
    referenceValues: Array<string>;
    selectedValues: Array<string>;
    hasAnyValueCheckbox?: boolean;
    labelFormatter?: (value: string) => string;
    onSelectionChange: (selectedValues: Array<string>) => void;
}

export default function FilterCheckboxesGroup(props: CheckboxesGroupProps) {
    const { hasAnyValueCheckbox, referenceValues, selectedValues, onSelectionChange, labelFormatter } = props;

    return (
        <Box pl={1}>
            <FormGroup>
                {
                    (hasAnyValueCheckbox ?? true) &&
                    <FormControlLabel
                        key='all'
                        control={
                            <Checkbox
                                name="manufacturer"
                                checked={selectedValues.length === 0}
                                onChange={() => onSelectionChange([])}
                                size='small'
                                sx={{ boxSizing: 'border-box', height: '25px' }}
                            />
                        }
                        label={<Typography variant='body2'>Any</Typography>}
                    />
                }
                {
                    referenceValues.map(refVal =>
                        <FormControlLabel
                            key={refVal}
                            control={
                                <Checkbox
                                    size='small'
                                    name="manufacturer"
                                    sx={{ boxSizing: 'border-box', height: '25px' }}
                                    checked={selectedValues.includes(refVal)}
                                    onChange={(e) => onSelectionChange(e.target.checked
                                        ? [...selectedValues, refVal]
                                        : selectedValues.filter(v => v !== refVal)
                                    )}
                                />
                            }
                            label={<Typography variant='body2'>{labelFormatter ? labelFormatter(refVal) : refVal}</Typography>}
                        />)
                }
            </FormGroup>
        </Box>
    );
}