import { PCPart } from '../../shared/models/pcPart';
import clientSideFilterConfigs from './clientSideFilterConfigs';
import QUERY_PARAM_KEYS from '../../pcpart-browsing/models/queryParamKeys';
import { PCPartType } from '../../shared/models/pcPartType';
import { ClientSidePCPartFilter, PCPartFilterOperation } from './pcPartFilter';
import { AppQueryParams } from '../../../app/customHooks';

export class ClientSidePCPartFilterCollection {
    private _filters;

    constructor(filters: Map<string, ClientSidePCPartFilter>) {
        this._filters = filters;
    }

    has(key: string) {
        return this._filters.has(key);
    }

    getAll() {
        return Array.from(this._filters.values());
    }

    get(key: string) {
        return this._filters.get(key);
    }

    getValues(key: string) {
        return this._filters.get(key)?.values ?? [];
    }

    getFirstValue(key: string) {
        return this._filters.get(key)?.values?.[0];
    }

    static fromAppQueryParams(queryParams: AppQueryParams) {
        const pcPartType = (queryParams[QUERY_PARAM_KEYS.pcPartType] ?? PCPartType.Any) as PCPartType;
        const filtersForPCPartType = clientSideFilterConfigs[pcPartType];

        const filtersMap = new Map<string, ClientSidePCPartFilter>();
        filtersForPCPartType.forEach(f => {
            filtersMap.set(f.queryParamKey, {
                ...f,
                values: queryParams[f.queryParamKey]?.split(',') ?? []
            });
        });

        return new ClientSidePCPartFilterCollection(filtersMap);
    }

    applyTo(pcParts: PCPart[]) {
        const activeFilters = Array.from(this._filters.values()).filter(f => f.values.length > 0 && f.values[0]);
        return activeFilters.length === 0
            ? pcParts
            : pcParts.filter(pcPart => activeFilters.every(f => this.isMatch(pcPart, f)));
    }

    isMatch(pcPart: PCPart, filter: ClientSidePCPartFilter) {
        if (filter.pcPartProperty === 'specifications' && !filter.pcPartsecondLevelProperty) {
            return false; // Match if invalid filter
        }
        const actualValue = filter.pcPartProperty === 'specifications'
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ? pcPart.specifications[filter.pcPartsecondLevelProperty!]
            : pcPart[filter.pcPartProperty];

        switch (filter.operation) {
            case PCPartFilterOperation.ExactValueMatch: {
                return filter.values.length === 0 || filter.values.some(v => v == actualValue);
            }
            case PCPartFilterOperation.Numeric_GreaterThanOrEqualTo: {
                return actualValue >= filter.values[0];
            }
            case PCPartFilterOperation.Numeric_LessThanOrEqualTo: {
                return actualValue <= filter.values[0];
            }
            case PCPartFilterOperation.CustomPredicate: {
                if (!filter.predicate) {
                    // Bad filter return true for now
                    return false;
                }
                return filter.predicate(pcPart, filter.values);
            }
            default: return true;
        }
    }
}
