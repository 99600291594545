export enum PCPartType {
    Any = 'Any',
    Processor = 'Processor',
    GraphicsCard = 'GraphicsCard',
    Motherboard = 'Motherboard',
    Memory = 'Memory',
    SSD = 'SSD',
    PowerSupplyUnit = 'PowerSupplyUnit',
    DesktopHDD = 'DesktopHDD',
    Null = 'Null'
    // Monitors = 'Monitors',
    // Coolers = 'Coolers',
    // Casing = 'Casing',
    // Peripherals
    // UPS = 'UPS',
}
