import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import { useMemo } from 'react';
import FilterAreaProps from './filterAreaProps';
import { selectDistinct } from '../../../common/helpers';
import FILTER_KEYS from '../models/pcPartFilterKeys';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';

const PROCESSOR_CORE_COUNTS = ['2', '4', '6', '8', '10', '12', '14', '16', '18', '20', '24'];

export default function ProcessorFilter(props: FilterAreaProps) {
    const { pcParts, filters, onFilterUpdate } = props;

    const availableSeries = useMemo(() => selectDistinct(pcParts.map(p => p.specifications.series)), [pcParts]);

    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Cores'>
                <FilterCheckboxesGroup
                    referenceValues={PROCESSOR_CORE_COUNTS}
                    selectedValues={filters.getValues(FILTER_KEYS.cores)}
                    onSelectionChange={values => onFilterUpdate(FILTER_KEYS.cores, values)}
                />
            </FilterSection>
            <FilterSection title='Series'>
                <FilterCheckboxesGroup
                    referenceValues={availableSeries}
                    selectedValues={filters.getValues(FILTER_KEYS.series)}
                    onSelectionChange={values => onFilterUpdate(FILTER_KEYS.series, values)}
                />
            </FilterSection>
        </FilterAreaWithCollapsibleChildren>
    );
}