import FILTER_KEYS from '../models/pcPartFilterKeys';
import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import FilterAreaProps from './filterAreaProps';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';
import RangeSlider from './RangeSlider';
import { Box } from '@mui/material';

const RATING_VALUES = ['Bronze', 'Silver', 'Gold', 'Platinum'];

export default function PowerSupplyUnitFilter(props: FilterAreaProps) {
    const { filters, onFilterUpdate } = props;

    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Wattage'>
                <Box
                    pt={5}
                    ml={3}
                >
                    <RangeSlider
                        min={100}
                        max={2000}
                        step={10}
                        unitLabel='W'
                        value={{
                            min: +(filters.getFirstValue(FILTER_KEYS.priceMin) ?? 100),
                            max: +(filters.getFirstValue(FILTER_KEYS.priceMax) ?? 2000)
                        }}
                        onChange={(range) => {
                            onFilterUpdate(FILTER_KEYS.wattageMin, [range.min.toString()]);
                            onFilterUpdate(FILTER_KEYS.wattageMax, [range.max.toString()]);
                        }}
                    />
                </Box>
            </FilterSection>
            <FilterSection title='Rating'>
                <FilterCheckboxesGroup
                    referenceValues={RATING_VALUES}
                    selectedValues={filters.getValues(FILTER_KEYS.psuRating)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.psuRating, values)}
                />
            </FilterSection>
        </FilterAreaWithCollapsibleChildren>
    );
}