import { Routes, Route, Navigate } from 'react-router-dom';
import PCPartsBrowsingPage from './features/pcpart-browsing/PCPartsBrowsingPage';
import PCPartDetailsPage from './features/pcpart-details/PCPartDetailsPage';
import { Alert, Box } from '@mui/material';
import App from './App';

const InvalidUrlPage = () =>
    <Box
        maxWidth={600}
        ml='auto'
        mr='auto'
        mt={1}
    >
        <Alert color='error'>Invalid Url. Check the Url and try again!</Alert>
    </Box>;

export const appRoutes = (
    <Routes>
        <Route path="/" element={<App />}>
            <Route path='browse' element={<PCPartsBrowsingPage />} />
            <Route path='details/:id' element={<PCPartDetailsPage />} />
            <Route path='' element={<Navigate to='browse' replace />} />
            <Route path='*' element={<InvalidUrlPage />} />
        </Route>
    </Routes>
);