import QUERY_PARAM_KEYS from '../../pcpart-browsing/models/queryParamKeys';
import { PCPartType } from '../../shared/models/pcPartType';
import { ClientSidePCPartFilter, PCPartFilterOperation } from './pcPartFilter';

const commonFilters: ClientSidePCPartFilter[] = [
    {
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'manufacturer',
        queryParamKey: QUERY_PARAM_KEYS.manufacturer,
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        pcPartProperty: 'pcPartStoreListings',
        queryParamKey: QUERY_PARAM_KEYS.pcPartStore,
        operation: PCPartFilterOperation.CustomPredicate,
        predicate: (pcPart, values) => values.length == 0 || pcPart.pcPartStoreListings.map(l => l.pcPartStore).some(l => values.includes(l)),
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.priceMin,
        pcPartProperty: 'averagePrice',
        operation: PCPartFilterOperation.Numeric_GreaterThanOrEqualTo,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.priceMax,
        pcPartProperty: 'averagePrice',
        operation: PCPartFilterOperation.Numeric_LessThanOrEqualTo,
        values: []
    },
];

const processorFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        queryParamKey: QUERY_PARAM_KEYS.series,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'series',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.cores,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'coreCount',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    }
];
const graphicsCardFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        queryParamKey: QUERY_PARAM_KEYS.chipset,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'chip',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.vRam,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'vram',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    }
];
const motherboardFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        queryParamKey: QUERY_PARAM_KEYS.chipset,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'chipset',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.memoryType,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'memoryType',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    }
];
const memoryFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        queryParamKey: QUERY_PARAM_KEYS.memoryType,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'type',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.memorySpeed,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'speed',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    }
];
const ssdFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        queryParamKey: QUERY_PARAM_KEYS.capacity,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'capacity',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.ssdType,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'connectorType',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    }
];
const powerSupplyUnitFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        queryParamKey: QUERY_PARAM_KEYS.psuRating,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'rating',
        operation: PCPartFilterOperation.ExactValueMatch,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.wattageMin,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'wattage',
        operation: PCPartFilterOperation.Numeric_GreaterThanOrEqualTo,
        values: []
    },
    {
        queryParamKey: QUERY_PARAM_KEYS.wattageMax,
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'wattage',
        operation: PCPartFilterOperation.Numeric_LessThanOrEqualTo,
        values: []
    }
];
const desktopHDDFilters: ClientSidePCPartFilter[] = [
    ...commonFilters,
    {
        pcPartProperty: 'specifications',
        pcPartsecondLevelProperty: 'capacity',
        queryParamKey: QUERY_PARAM_KEYS.capacity,
        operation: PCPartFilterOperation.CustomPredicate,
        predicate: (pcPart, values) => {
            const requiredValues = values.map(val => {
                const intVal = parseInt(val);
                return intVal < 1000 ? intVal * 1000 : intVal;
            });
            return requiredValues.some(val => pcPart.specifications.capacity === val);
        },
        values: []
    }
];

const clientSideFilterConfigs: { [pcPartType: string]: ClientSidePCPartFilter[] } = {
    [PCPartType.Null]: commonFilters,
    [PCPartType.Any]: commonFilters,
    [PCPartType.Processor]: processorFilters,
    [PCPartType.GraphicsCard]: graphicsCardFilters,
    [PCPartType.Motherboard]: motherboardFilters,
    [PCPartType.Memory]: memoryFilters,
    [PCPartType.SSD]: ssdFilters,
    [PCPartType.PowerSupplyUnit]: powerSupplyUnitFilters,
    [PCPartType.DesktopHDD]: desktopHDDFilters
};
export default clientSideFilterConfigs;