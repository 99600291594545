import { Box, Skeleton } from '@mui/material';
import FilterAreaWithCollapsibleChildren, { FilterSection } from '../../pcpart-filtering/components/FilterAreaWithCollapsibleChildren';
import FilterCheckboxesGroup from '../../pcpart-filtering/components/FilterCheckboxesGroup';
import GraphicsCardFilter from '../../pcpart-filtering/components/GraphicsCardFilter';
import ManufacturerFilter from '../../pcpart-filtering/components/ManufacturerFilter';
import MemoryFilter from '../../pcpart-filtering/components/MemoryFilter';
import MotherboardFilter from '../../pcpart-filtering/components/MotherboardFilter';
import PowerSupplyUnitFilter from '../../pcpart-filtering/components/PowerSupplyUnitFilter';
import PriceFilter from '../../pcpart-filtering/components/PriceFilter';
import ProcessorFilter from '../../pcpart-filtering/components/ProcessorFilter';
import SSDFilter from '../../pcpart-filtering/components/SSDFilter';
import FILTER_KEYS from '../../pcpart-filtering/models/pcPartFilterKeys';
import { PCPartType } from '../../shared/models/pcPartType';
import DesktopHDDFilter from '../../pcpart-filtering/components/DesktopHDDFilter';
import { usePCPartFilters, useSelectQueryParam } from '../../../app/customHooks';
import { useAppSelector } from '../../../app/hooks';
import QUERY_PARAM_KEYS from '../models/queryParamKeys';

const PCPART_STORES = ['Nanotek', 'Redline', 'Barclays'];

export default function PCPartsFilter() {
    const [filters, setFilter] = usePCPartFilters();

    const isLoading = useAppSelector(s => s.app.isLoading);
    const pcParts = useAppSelector(s => s.app.pcParts);
    const pcPartType = useSelectQueryParam<PCPartType>(QUERY_PARAM_KEYS.pcPartType, PCPartType.Any);

    if (isLoading) {
        return (
            <Box height={800}>
                <Skeleton variant='rectangular' height={800} />
            </Box>
        );
    }

    let pcPartTypeSpecificFilters = null;
    switch (pcPartType) {
        case PCPartType.Null: {
            pcPartTypeSpecificFilters =
                <></>;
            break;
        }
        case PCPartType.Processor: {
            pcPartTypeSpecificFilters =
                <ProcessorFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        case PCPartType.GraphicsCard: {
            pcPartTypeSpecificFilters =
                <GraphicsCardFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        case PCPartType.Motherboard: {
            pcPartTypeSpecificFilters =
                <MotherboardFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        case PCPartType.Memory: {
            pcPartTypeSpecificFilters =
                <MemoryFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        case PCPartType.SSD: {
            pcPartTypeSpecificFilters =
                <SSDFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        case PCPartType.DesktopHDD: {
            pcPartTypeSpecificFilters =
                <DesktopHDDFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        case PCPartType.PowerSupplyUnit: {
            pcPartTypeSpecificFilters =
                <PowerSupplyUnitFilter
                    filters={filters}
                    pcParts={pcParts}
                    onFilterUpdate={setFilter}
                />;
            break;
        }
        default:
            break;
    }

    return (
        <Box ml={1}>
            <FilterAreaWithCollapsibleChildren>
                <FilterSection title='Manufacturer'>
                    <ManufacturerFilter
                        filters={filters}
                        pcParts={pcParts}
                        onFilterUpdate={setFilter}
                    />
                </FilterSection>
                <FilterSection title='Price' openByDefault={true}>
                    <PriceFilter
                        filters={filters}
                        pcParts={pcParts}
                        onFilterUpdate={setFilter}
                        minPrice={0} 
                        maxPrice={1000000} 
                    />
                </FilterSection>
                <FilterSection title='Store' openByDefault={true}>
                    <FilterCheckboxesGroup
                        referenceValues={PCPART_STORES}
                        selectedValues={filters.getValues(FILTER_KEYS.pcPartStore)}
                        onSelectionChange={(values) => setFilter(FILTER_KEYS.pcPartStore, values)}
                    />
                </FilterSection>
            </FilterAreaWithCollapsibleChildren>
            {pcPartTypeSpecificFilters}
        </Box>
    );
}