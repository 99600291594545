import { selectDistinct } from '../../../common/helpers';
import FILTER_KEYS from '../models/pcPartFilterKeys';
import FilterCheckboxesGroup from './FilterCheckboxesGroup';
import FilterAreaProps from './filterAreaProps';
import FilterAreaWithCollapsibleChildren, { FilterSection } from './FilterAreaWithCollapsibleChildren';

const SSD_FORMFACTORS = ['M2', 'SATA'];

export default function SSDFilter(props: FilterAreaProps) {
    const { pcParts, filters, onFilterUpdate } = props;

    const capacities = selectDistinct(pcParts.filter(p => p.specifications.capacity).map(p => p.specifications.capacity)).sort();

    return (
        <FilterAreaWithCollapsibleChildren>
            <FilterSection title='Capacity'>
                <FilterCheckboxesGroup
                    referenceValues={capacities}
                    selectedValues={filters.getValues(FILTER_KEYS.capacity)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.capacity, values)}
                />
            </FilterSection>
            <FilterSection title='Form Factor'>
                <FilterCheckboxesGroup
                    referenceValues={SSD_FORMFACTORS}
                    selectedValues={filters.getValues(FILTER_KEYS.ssdType)}
                    onSelectionChange={(values) => onFilterUpdate(FILTER_KEYS.ssdType, values)}
                />
            </FilterSection>
        </FilterAreaWithCollapsibleChildren>
    );
}